export const feedbackList = [
  {
    id: 1,
    client: 'Silvio',
    location: 'São Paulo, SP',
    depoiment: `"Eu e minha família vínhamos há quase um ano em um aperto financeiro e psicológico sem precedentes. Foi quando conhecia a CashMe, porque já estava pensando em vender a casa para quitar todas as dívidas. Quando entrei em contato e contei a minha história, eu recebi um direcionamento que salvou a minha casa e restaurou o meu crédito na praça. Estamos muito felizes."`
  },
  {
    id: 2,
    client: 'José Felipe da Silva Neto',
    location: 'São Paulo, SP',
    depoiment: `"É muito bom quando se pode conectar com uma empresa como a CashMe, de maneira rápida e eficiente, com ótima taxa de mercado. Fiquei tranquilo quando vi que pertence ao Grupo Cyrela. Ainda bem que existe uma empresa assim, pois as restrições de outras instituições financeiras são muito grandes."`
  },
  {
    id: 3,
    client: 'José Rodrigues',
    location: 'São Paulo, SP',
    depoiment: `"Dei um voto de confiança porque senti segurança e transparência nas orientações. A minha operação foi a primeira em contato digital. Foi uma experiência muito inovadora, prática e sem problemas."`
  }
]
