import styled from 'styled-components'

import { theme } from 'styles/theme'

const { colors, media, space, radii } = theme

export const CarouselWrapper = styled.div`
  swiper-container {
    overflow: visible;

    .swiper-pagination {
      text-align: left;
    }

    .swiper-pagination-bullet {
      width: ${space['spacing-xxs']};
      height: ${space['spacing-xxs']};
      background-color: ${colors['neutral-icon-disabled']};
      border-radius: ${space['spacing-sm']};
      cursor: pointer;
      transition: width 0.34s ease;
    }

    .swiper-pagination-bullet-active {
      width: ${space['spacing-md']};
      background-color: ${colors['cyan-background-strong-default']};

      @media (${media.tablet}) {
        width: ${space['spacing-lg']};
      }
    }
  }

  swiper-slide {
    height: auto;
  }
`

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: calc(100% - ${space['spacing-lg']});

  @media (${media.desktop}) {
    max-width: 43.5rem;
  }
`

export const SlideControls = styled.ul`
  display: flex;
  align-items: center;
  gap: ${space['spacing-sm']};
`

export const SlideControlItem = styled.li`
  width: ${space['spacing-xxs']};
  height: ${space['spacing-xxs']};
  background-color: ${colors['neutral-icon-disabled']};
  border-radius: ${space['spacing-sm']};
  cursor: pointer;
  transition: width 0.34s ease;

  &[data-state='active'] {
    width: ${space['spacing-md']};
    background-color: ${colors['cyan-background-strong-default']};

    @media (${media.tablet}) {
      width: ${space['spacing-lg']};
    }
  }
`

export const ArrowControls = styled.div`
  display: flex;
  gap: ${space['spacing-xxs']};

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: ${space['spacing-xl']};
    border-radius: ${radii['corner-radius-circular']};
  }
`
