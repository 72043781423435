import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Button } from '@cash-me/react-components'
import type { Swiper } from 'swiper/types'

import { CarouselProps } from './carousel.model'
import * as S from './styles'

type SwiperReference = HTMLElement & {
  swiper: Swiper
}

export const Carousel = ({
  carouselStyle = {},
  navigation = false,
  onChangeIndex = null,
  children,
  ...props
}: CarouselProps) => {
  const swiperElRef = useRef<SwiperReference | null>(null)
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const handleChangeIndex = useCallback(() => {
    if (!swiperElRef.current) return

    const swiperInstance = (swiperElRef.current as SwiperReference).swiper
    if (swiperInstance) {
      setCurrentIndex(swiperInstance.activeIndex)
      if (onChangeIndex) onChangeIndex(swiperInstance.activeIndex)
    }
  }, [onChangeIndex])

  const slideNext = useCallback(() => {
    if (!swiperElRef.current) return

    const swiperInstance = (swiperElRef.current as SwiperReference).swiper
    if (swiperInstance) swiperInstance.slideNext()
  }, [])

  const slidePrev = useCallback(() => {
    if (!swiperElRef.current) return

    const swiperInstance = (swiperElRef.current as SwiperReference).swiper
    if (swiperInstance) swiperInstance.slidePrev()
  }, [])

  const slideTo = useCallback((index: number) => {
    if (!swiperElRef.current) return

    const swiperInstance = (swiperElRef.current as SwiperReference).swiper
    if (swiperInstance) swiperInstance.slideTo(index)
  }, [])

  const renderControl = () => (
    <S.ControlsWrapper className="controls-wrapper">
      <S.SlideControls>
        {React.Children.map(children, (_, index) => (
          <S.SlideControlItem
            data-state={currentIndex === index ? 'active' : 'inactive'}
            onClick={() => slideTo(index)}
          />
        ))}
      </S.SlideControls>
      {navigation && (
        <S.ArrowControls>
          <Button iconName="arrowLeft" aria-label="Slide anterior" variants="icon-only" size="sm" onClick={slidePrev} />
          <Button iconName="arrowRight" aria-label="Próximo slide" variants="icon-only" size="sm" onClick={slideNext} />
        </S.ArrowControls>
      )}
    </S.ControlsWrapper>
  )

  const renderItems = useCallback(
    () => React.Children.map(children, child => <swiper-slide>{child}</swiper-slide>),
    [children]
  )

  useEffect(() => {
    // listen for Swiper events using addEventListener
    const swiperElement = swiperElRef.current
    if (swiperElement) {
      swiperElement.addEventListener('swiperslidechange', handleChangeIndex)
      return () => swiperElement.removeEventListener('swiperslidechange', handleChangeIndex)
    }
  }, [handleChangeIndex])

  return (
    <>
      <S.CarouselWrapper>
        <swiper-container
          ref={swiperElRef}
          space-between={props['space-between'] ?? '16'}
          slides-per-view={props['slides-per-view'] ?? '1'}
          set-wrapper-size={true}
          keyboard
          style={carouselStyle}
          {...props}
        >
          {renderItems()}
        </swiper-container>
      </S.CarouselWrapper>
      {swiperElRef && renderControl()}
    </>
  )
}
